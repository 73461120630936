<template>
    <v-card>
        <v-card-text>
            <div>
                <div>
                    <v-expansion-panels popout>
                        <v-expansion-panel v-for="(item, i) in Object.keys($store.state.vertraege.vertraege)" :key="i"
                            @click="getVertrag(item)">
                            <v-expansion-panel-header>{{ item }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-card>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <v-checkbox v-model="$store.state.vertraege.vertraege[item].konfiguriert"
                                                    :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                    label="konfiguriert?" @change="onKonfiguriert($event, item)">
                                                </v-checkbox>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-autocomplete v-model="$store.state.vertraege.vertraege[item].product"
                                                        :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                        :items="$store.state.vertraege.alleProduktnamen" dense filled
                                                        label="Auslösendes Produkt"
                                                        @change="onVertragsProduktChange($event, item)"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field label="Anzahl der Tranchen"
                                                        :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                        v-model="anzahl" filled type="number"
                                                        @change="onNumberChange($event, item)" />

                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field label="automatisch Schließen nach"
                                                        v-model="vertragAutoclose" filled type="datetime-local"
                                                        :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                        @change="onVertragAutoclose($event, item)" />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-card style="width:100%">
                                                    <v-card-title>Handelszeiten</v-card-title>
                                                    <v-card-text>
                                                        <v-radio-group
                                                            v-model="trading.tradingtype">
                                                            <v-radio label="Standardzeiten verwenden"
                                                                value="standard"></v-radio>
                                                            <v-radio label="wie konfiguriert"
                                                                value="konfiguriert"></v-radio>
                                                            <v-container v-if="trading.tradingtype == 'konfiguriert'">
                                                                <v-simple-table dense>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><v-checkbox v-model="trading.tradingtimes.monday.active" label="Montag"></v-checkbox></td>
                                                                                <td><v-text-field label="Von"
                                                                                        :value=trading.tradingtimes.monday.from
                                                                                        type="time"></v-text-field></td>
                                                                                <td><v-text-field label="Bis"
                                                                                        :value=trading.tradingtimes.monday.to
                                                                                        type="time"></v-text-field></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><v-checkbox v-model="trading.tradingtimes.tuesday.active" label="Dienstag"></v-checkbox></td>
                                                                                <td><v-text-field label="Von"
                                                                                        :value=trading.tradingtimes.tuesday.from
                                                                                        type="time"></v-text-field></td>
                                                                                <td><v-text-field label="Bis"
                                                                                        :value=trading.tradingtimes.tuesday.to
                                                                                        type="time"></v-text-field></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><v-checkbox v-model="trading.tradingtimes.wednesday.active" label="Mittwoch"></v-checkbox></td>
                                                                                <td><v-text-field label="Von"
                                                                                        :value=trading.tradingtimes.wednesday.from
                                                                                        type="time"></v-text-field></td>
                                                                                <td><v-text-field label="Bis"
                                                                                        :value=trading.tradingtimes.wednesday.to
                                                                                        type="time"></v-text-field></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><v-checkbox v-model="trading.tradingtimes.thursday.active" label="Donnerstag"></v-checkbox></td>
                                                                                <td><v-text-field label="Von"
                                                                                        :value=trading.tradingtimes.thursday.from
                                                                                        type="time"></v-text-field></td>
                                                                                <td><v-text-field label="Bis"
                                                                                        :value=trading.tradingtimes.thursday.to
                                                                                        type="time"></v-text-field></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><v-checkbox v-model="trading.tradingtimes.friday.active" label="Freitag"></v-checkbox></td>
                                                                                <td><v-text-field label="Von"
                                                                                        :value=trading.tradingtimes.friday.from
                                                                                        type="time"></v-text-field></td>
                                                                                <td><v-text-field label="Bis"
                                                                                        :value=trading.tradingtimes.friday.to
                                                                                        type="time"></v-text-field></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><v-checkbox v-model="trading.tradingtimes.saturday.active" label="Samstag"></v-checkbox></td>
                                                                                <td><v-text-field label="Von"
                                                                                        :value=trading.tradingtimes.saturday.from
                                                                                        type="time"></v-text-field></td>
                                                                                <td><v-text-field label="Bis"
                                                                                        :value=trading.tradingtimes.saturday.to
                                                                                        type="time"></v-text-field></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><v-checkbox v-model="trading.tradingtimes.sunday.active" label="Sonntag"></v-checkbox></td>
                                                                                <td><v-text-field label="Von"
                                                                                        :value=trading.tradingtimes.sunday.from
                                                                                        type="time"></v-text-field></td>
                                                                                <td><v-text-field label="Bis"
                                                                                        :value=trading.tradingtimes.sunday.to
                                                                                        type="time"></v-text-field></td>
                                                                            </tr>
                                                                        </tbody>
                                                                </v-simple-table>
                                                            </v-container>
                                                        </v-radio-group>
                                                    </v-card-text>
                                                </v-card>
                                            </v-row>
                                            <v-row>
                                                <v-card style="width:100%">
                                                    <v-card-title>
                                                        Zeitbereiche
                                                        <v-spacer />
                                                        <v-text-field label="Neuen Zeitbereich erstellen"
                                                            v-model="neuerZeitbereichName"
                                                            :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                            @change="createNewZeitbereich(item)"></v-text-field>
                                                        <v-btn class="mx-2" fab dark small color="green lighten-2"
                                                            @click="createNewZeitbereich(item)"><v-icon>mdi-folder-plus</v-icon></v-btn>
                                                    </v-card-title>
                                                    <v-card-text :key="zeitbereichsKey">

                                                        <v-expansion-panels>
                                                            <v-expansion-panel
                                                                v-for="(zeitbereich, j) in Object.keys($store.state.vertraege.vertraege[item].zeitbereiche)"
                                                                :key="j">
                                                                <v-expansion-panel-header>{{ zeitbereich
                                                                }}</v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <v-container>
                                                                        <v-row>
                                                                            <v-col cols="6">
                                                                                <v-text-field label="Stopp-Loss"
                                                                                    v-model="$store.state.vertraege.vertraege[item].zeitbereiche[zeitbereich].delta"
                                                                                    filled type="number" step=".01"
                                                                                    prefix="€"
                                                                                    @change="updateZeitbereich($event, item, zeitbereich)" />
                                                                            </v-col>
                                                                            <v-col cols="6">
                                                                                <v-text-field label="Limit"
                                                                                    v-model="$store.state.vertraege.vertraege[item].zeitbereiche[zeitbereich].value"
                                                                                    filled type="number" step=".01"
                                                                                    prefix="€"
                                                                                    @change="updateZeitbereich($event, item, zeitbereich)" />
                                                                            </v-col>

                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-card style="width:100%"
                                                                                :key="zeitbereichsTranchenKey">
                                                                                <v-card-text>
                                                                                    <v-data-table
                                                                                        :headers="zeitbereichsHeader"
                                                                                        :items="zeitbereichTableData($store.state.vertraege.vertraege[item].zeitbereiche[zeitbereich].bereiche)"
                                                                                        class="elevation-1">
                                                                                        <template v-slot:item.id="{ item }">
                                                                                            {{ new
                                                                                                Date(item.id *
                                                                                                    1000).toLocaleString('de-DE') }}

                                                                                        </template>
                                                                                        <template
                                                                                            v-slot:item.actions="{ item }">
                                                                                            <v-icon small class="mr-2"
                                                                                                @click="editItem(item)">
                                                                                                mdi-pencil
                                                                                            </v-icon>
                                                                                            <v-icon small
                                                                                                @click="deleteItem(item, zeitbereich)">
                                                                                                mdi-delete
                                                                                            </v-icon>
                                                                                        </template>
                                                                                        <template v-slot:top>
                                                                                            <v-toolbar flat>
                                                                                                <v-toolbar-title>Zuordnungen</v-toolbar-title>
                                                                                                <v-divider class="mx-4"
                                                                                                    inset
                                                                                                    vertical></v-divider>
                                                                                                <v-spacer></v-spacer>
                                                                                                <v-dialog
                                                                                                    v-model="zeitbereichsDialog"
                                                                                                    max-width="500px">
                                                                                                    <template
                                                                                                        v-slot:activator="{ on, attrs }">
                                                                                                        <v-btn
                                                                                                            color="primary"
                                                                                                            dark
                                                                                                            class="mb-2"
                                                                                                            v-bind="attrs"
                                                                                                            v-on="on">
                                                                                                            Neue Zuordnung
                                                                                                        </v-btn>
                                                                                                    </template>
                                                                                                    <v-card>
                                                                                                        <v-card-title>
                                                                                                            <span
                                                                                                                class="text-h5">{{
                                                                                                                    formTitle
                                                                                                                }}</span>
                                                                                                        </v-card-title>

                                                                                                        <v-card-text>
                                                                                                            <v-container>
                                                                                                                <v-row>
                                                                                                                    <v-col
                                                                                                                        cols="12">
                                                                                                                        <v-text-field
                                                                                                                            v-model="editedItem.id"
                                                                                                                            label="Beginn"
                                                                                                                            type="datetime-local"></v-text-field>
                                                                                                                    </v-col>
                                                                                                                </v-row>
                                                                                                                <v-row>
                                                                                                                    <v-col
                                                                                                                        cols="12">
                                                                                                                        <v-select
                                                                                                                            v-model="editedItem.namen"
                                                                                                                            :items="getTranchenList(item, zeitbereich)"
                                                                                                                            chips
                                                                                                                            label="zugeordnete Tranchen "
                                                                                                                            multiple></v-select>
                                                                                                                    </v-col>
                                                                                                                </v-row>
                                                                                                            </v-container>
                                                                                                        </v-card-text>

                                                                                                        <v-card-actions>
                                                                                                            <v-spacer></v-spacer>
                                                                                                            <v-btn
                                                                                                                color="blue darken-1"
                                                                                                                :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                                                                                text
                                                                                                                @click="close">
                                                                                                                Cancel
                                                                                                            </v-btn>
                                                                                                            <v-btn
                                                                                                                color="blue darken-1"
                                                                                                                :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                                                                                text
                                                                                                                @click="save(item, zeitbereich)">
                                                                                                                Save
                                                                                                            </v-btn>
                                                                                                        </v-card-actions>
                                                                                                    </v-card>
                                                                                                </v-dialog>
                                                                                                <v-dialog
                                                                                                    v-model="zeitbereichsDialogDelete"
                                                                                                    max-width="500px">
                                                                                                    <v-card>
                                                                                                        <v-card-title
                                                                                                            class="text-h5">Sind
                                                                                                            Sie sicher, dass
                                                                                                            Sie diese
                                                                                                            Zuordnung
                                                                                                            löschen
                                                                                                            wollen?</v-card-title>
                                                                                                        <v-card-actions>
                                                                                                            <v-spacer></v-spacer>
                                                                                                            <v-btn
                                                                                                                color="blue darken-1"
                                                                                                                :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                                                                                text
                                                                                                                @click="closeDelete">Cancel</v-btn>
                                                                                                            <v-btn
                                                                                                                color="blue darken-1"
                                                                                                                :disabled="$store.state.vertraege.vertraege[item].konfiguriert"
                                                                                                                text
                                                                                                                @click="deleteItemConfirm">OK</v-btn>
                                                                                                            <v-spacer></v-spacer>
                                                                                                        </v-card-actions>
                                                                                                    </v-card>
                                                                                                </v-dialog>
                                                                                            </v-toolbar>
                                                                                        </template>
                                                                                    </v-data-table>
                                                                                </v-card-text>
                                                                            </v-card>
                                                                        </v-row>

                                                                    </v-container>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>

                                                    </v-card-text>
                                                </v-card>
                                            </v-row>
                                            <v-row>
                                                <v-card style="width:100%">
                                                    <v-card-title>Tranchen</v-card-title>
                                                    <v-card-text>

                                                        <v-simple-table :key="tranchenKey">
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left">
                                                                            Tranche
                                                                        </th>
                                                                        <th class="text-left">
                                                                            Status
                                                                        </th>
                                                                        <th class="text-left">
                                                                            Datum
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="value in sortedTranchen(item)"
                                                                        :key="value.sort">
                                                                        <td>Tranche {{ value.sort }}</td>
                                                                        <td>{{ value.status }}</td>
                                                                        <td v-if="value.timestamp">{{ new
                                                                            Date(value.timestamp.seconds *
                                                                                1000).toLocaleString('de-DE') }}</td>
                                                                        <td v-else>&nbsp;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-row>
                                            <v-row>
                                                <v-card style="width:100%" :key="vertragsnamenKey">
                                                    <v-card-title>Vertragsnummern</v-card-title>
                                                    <v-card-text>
                                                        <v-simple-table>
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left">
                                                                            #
                                                                        </th>
                                                                        <th class="text-left">
                                                                            Vertrag
                                                                        </th>
                                                                        <th class="text-left">
                                                                            Vertragsnehmer
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="vname in $store.state.vertraege.vertragsnummern[item]"
                                                                        :key="vname.name">
                                                                        <td>{{
                                                                            $store.state.vertraege.vertragsnummern[item].indexOf(vname)
                                                                            + 1
                                                                        }}</td>
                                                                        <td>{{ vname }}</td>
                                                                        <td>{{ $store.state.vertraege.vertragsnehmer[vname]
                                                                        }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-row>

                                        </v-container>

                                    </v-form>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
//import { firestore } from "@/plugins/firebase";
//import { collection, getDocs } from "@firebase/firestore";

import { format } from "date-fns"
export default {
    data() {
        return {
            ausgesuchtesProdukt: "",
            aktuellerVertrag: null,
            vertraege: [],
            anzahl: 0,
            tranchenKey: 0,
            zeitbereichsKey: 0,
            zeitbereichsTranchenKey: 0,
            vertragsnamenKey: 0,
            neuerZeitbereichName: "",
            trading: {
                "current":  {
                    tradingtype: "standard",
                    tradingtimes: {
                        monday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        tuesday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        wednesday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        thursday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        friday: {
                            active: false,
                            from: "09:00:00",
                            to: "13:00:00"
                        },
                        saturday: {
                            active: false,
                            from: "00:00:00",
                            to: "00:00:00"
                        },
                        sunday: {
                            active: false,
                            from: "00:00:00",
                            to: "00:00:00"
                        }
                    }
                },
                "default": {
                    tradingtype: "standard",
                    tradingtimes: {
                        monday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        tuesday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        wednesday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        thursday: {
                            active: false,
                            from: "09:00:00",
                            to: "15:00:00"
                        },
                        friday: {
                            active: false,
                            from: "09:00:00",
                            to: "13:00:00"
                        },
                        saturday: {
                            active: false,
                            from: "00:00:00",
                            to: "00:00:00"
                        },
                        sunday: {
                            active: false,
                            from: "00:00:00",
                            to: "00:00:00"
                        }
                    }
                }
            },
            zeitbereichsHeader: [
                { text: "Start", value: "id" },
                { text: "zugehörige Tranchen", value: "namen", sortable: false },
                { text: "Status", value: "status", sortable: false },
                { text: "Actions", value: "actions", sortable: false },
            ],
            zeitbereichsDialog: false,
            zeitbereichsDialogDelete: false,
            editedIndex: -1,
            editedItem: {
                id: "",
                namen: [],
                status: "offen",

            },
            defaultItem: {
                id: "",
                namen: [],
                status: "offen",
            },
            deleteObject: null,
            vertragAutoclose: new Date()

        };
    },
    computed: {

        formTitle() {
            return this.editedIndex === -1 ? "Neue Zuordnung" : "Zuordnung bearbeiten"
        },
        showAutoclose() {
            if (!this.$store.state.vertraege.vertraege[this.aktuellerVertrag].autoclose) {
                return format(new Date(), "yyyy-MM-dd'T'HH:mm:00.000")
            }
            const date = new Date(this.$store.state.vertraege.vertraege[this.aktuellerVertrag].autoclose * 1000);
            return format(date, "yyyy-MM-dd'T'HH:mm:00.000")
        }
    },
    methods: {
        sortedTranchen(item) {
            const data = this.$store.state.vertraege.vertraege[item].tranchen
            if (!data) {
                return []
            }
            const ret = []
            for (const [key, value] of Object.entries(data)) {
                const idx = parseInt(key.split(" ")[1])
                value.sort = idx
                ret.push(value)
            }
            ret.sort((a, b) => a.sort - b.sort)
            return ret
        },
        getTranchenList(item, zeitbereich) {
            console.log(item, zeitbereich)
            const alleTranchen = Object.keys(this.$store.state.vertraege.vertraege[item].tranchen);
            console.log(zeitbereich)
            return alleTranchen
        },
        zeitbereichTableData(obj) {
            let out = [];
            if (obj != null) {
                for (const el in obj) {
                    out.push(obj[el])
                }
            }

            return out
            //return [{ id: 1, namen: ["1", "2"], status: "okay" }]
        },
        getVertrag: function (item) {
            console.log("getVertrag");
            this.$store.dispatch("attachVertragsnummern", { docid: item });
            this.$store.dispatch("attachVertragZeitbereiche", { docid: item });
            this.$store.dispatch("attachVertragTranchen", { docid: item }).then(() => {
                const counter = Object.keys(this.$store.state.vertraege.vertraege[item].tranchen).length;
                this.anzahl = counter;
            });
            if (this.$store.state.vertraege.vertraege[item].autoclose) {
                this.vertragAutoclose = format(new Date(this.$store.state.vertraege.vertraege[item].autoclose * 1000), "yyyy-MM-dd'T'HH:mm:00.000");
            } else {
                this.vertragAutoclose = format(new Date(0), "yyyy-MM-dd'T'HH:mm:00.000");
            }

            this.aktuellerVertrag = item
        },
        onKonfiguriert: function (change, item) {
            this.$store.dispatch("onKonfiguriert", { docid: item }).then(() => {
                this.tranchenKey++;
            })
        },
        onNumberChange: function (change, item) {
            const newNumber = parseInt(change)
            // const tranchenPrefix = "Tranche ";
            const counter = Object.keys(this.$store.state.vertraege.vertraege[item].tranchen).length;


            // wegfallende
            if (counter > newNumber) {
                for (const [key, value] of Object.entries(this.$store.state.vertraege.vertraege[item].tranchen)) {
                    if (value.sort > newNumber) {
                        console.log("delete" + key)
                        delete this.$store.state.vertraege.vertraege[item].tranchen[key]
                    }
                }
            } else {
                let idxNew = counter + 1
                for (idxNew; idxNew <= newNumber; idxNew++) {
                    const tranchenname = `Tranche ${idxNew}`
                    this.$store.state.vertraege.vertraege[item].tranchen[tranchenname] = {
                        "status": "offen"
                    }
                }
            }

            //this.tranchenKey++;


            this.$store.dispatch("updateTranchen", { item: item, value: newNumber, counter: counter }).then(() => {
                this.tranchenKey++;
            })

        },
        onVertragsProduktChange(change, item) {
            console.log(change, item);
            this.$store.state.vertraege.vertraege[item].product = change;
            this.$store.dispatch("updateVertragsprodukt", { item: item, value: change })
        },
        onVertragAutoclose(change, item) {
            console.log(change, item);
            const timestamp = Math.floor(new Date(change).valueOf() / 1000)
            this.$store.state.vertraege.vertraege[item].autoclose = timestamp;
            this.$store.dispatch("updateVertragsAutoclose", { item: item, value: timestamp })
        },
        createNewZeitbereich: function (item) {
            if (!this.neuerZeitbereichName || this.neuerZeitbereichName == "" || Object.keys(this.$store.state.vertraege.vertraege[item].zeitbereiche).includes(this.neuerZeitbereichName)) {
                return;
            }
            this.$store.state.vertraege.vertraege[item].zeitbereiche[this.neuerZeitbereichName] = {
                "id": this.neuerZeitbereichName,
                "bereiche": {},
                "delta": 0,
                "value": 0,
                "date": {
                    nanoseconds: 0,
                    seconds: Math.floor(Date.now() / 1000)
                }
            }
            this.$store.dispatch("insertZeitbereich", { item: item, zeitbereich: this.neuerZeitbereichName });
            this.zeitbereichsKey++;
            this.neuerZeitbereichName = ""
        },
        close() {
            this.zeitbereichsDialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.zeitbereichsDialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            this.deleteObject = {}
        },
        editItem(item) {
            const date = new Date(item.id * 1000);
            this.editedItem = {
                id: format(date, "yyyy-MM-dd'T'HH:mm:00.000"),
                namen: item.namen,
                status: item.status
            }
            this.zeitbereichsDialog = true
        },
        deleteItem(item, bereich) {
            this.deleteObject = {
                vertrag: this.aktuellerVertrag,
                bereich: bereich,
                id: item.id
            }
            this.zeitbereichsDialogDelete = true
        },
        updateZeitbereich(wert, item, zeitbereich) {
            console.log(wert, item, zeitbereich);
            this.$store.dispatch("updateZeitbereich", { item: item, zeitbereich: zeitbereich })
        },
        deleteItemConfirm() {
            delete this.$store.state.vertraege.vertraege[this.deleteObject.vertrag].zeitbereiche[this.deleteObject.bereich].bereiche[this.deleteObject.id];
            this.$store.dispatch("updateZeitbereich", { item: this.deleteObject.vertrag, zeitbereich: this.deleteObject.bereich })
            this.deleteObject = {}
            this.zeitbereichsTranchenKey++;
            this.closeDelete()
        },
        save(item, bereich) {
            console.log("save", item, bereich)
            const timestamp = Math.floor(new Date(this.editedItem.id).valueOf() / 1000);
            this.$store.state.vertraege.vertraege[item].zeitbereiche[bereich].bereiche[timestamp] = {
                id: timestamp,
                namen: this.editedItem.namen,
                status: "offen",
            }
            console.log("call dispatch");
            this.$store.dispatch("updateZeitbereich", { item: item, zeitbereich: bereich })
            this.zeitbereichsTranchenKey++;
            this.close()
        },
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login");
            return;
        }

        this.$store.dispatch("holeAlleProduktnamen");
        this.$store.dispatch("attachVertraege");
        this.$store.dispatch("getTradingTimes");
    },
    watch: {
        zeitbereichsDialog(val) {
            val || this.close()
        },
        zeitbereichsDialogDelete(val) {
            val || this.closeDelete()
        },
    },
};
</script>

<style></style>