var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-text", [
        _c("div", [
          _c(
            "div",
            [
              _c(
                "v-expansion-panels",
                { attrs: { popout: "" } },
                _vm._l(
                  Object.keys(_vm.$store.state.vertraege.vertraege),
                  function (item, i) {
                    return _c(
                      "v-expansion-panel",
                      {
                        key: i,
                        on: {
                          click: function ($event) {
                            return _vm.getVertrag(item)
                          },
                        },
                      },
                      [
                        _c("v-expansion-panel-header", [_vm._v(_vm._s(item))]),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-form",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                disabled:
                                                  _vm.$store.state.vertraege
                                                    .vertraege[item]
                                                    .konfiguriert,
                                                label: "konfiguriert?",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onKonfiguriert(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.$store.state.vertraege
                                                    .vertraege[item]
                                                    .konfiguriert,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.$store.state.vertraege
                                                      .vertraege[item],
                                                    "konfiguriert",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "$store.state.vertraege.vertraege[item].konfiguriert",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "4" },
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.$store.state.vertraege
                                                        .vertraege[item]
                                                        .konfiguriert,
                                                    items:
                                                      _vm.$store.state.vertraege
                                                        .alleProduktnamen,
                                                    dense: "",
                                                    filled: "",
                                                    label:
                                                      "Auslösendes Produkt",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onVertragsProduktChange(
                                                        $event,
                                                        item
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.$store.state.vertraege
                                                        .vertraege[item]
                                                        .product,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.$store.state
                                                          .vertraege.vertraege[
                                                          item
                                                        ],
                                                        "product",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "$store.state.vertraege.vertraege[item].product",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "4" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      "Anzahl der Tranchen",
                                                    disabled:
                                                      _vm.$store.state.vertraege
                                                        .vertraege[item]
                                                        .konfiguriert,
                                                    filled: "",
                                                    type: "number",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onNumberChange(
                                                        $event,
                                                        item
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.anzahl,
                                                    callback: function ($$v) {
                                                      _vm.anzahl = $$v
                                                    },
                                                    expression: "anzahl",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "4" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      "automatisch Schließen nach",
                                                    filled: "",
                                                    type: "datetime-local",
                                                    disabled:
                                                      _vm.$store.state.vertraege
                                                        .vertraege[item]
                                                        .konfiguriert,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onVertragAutoclose(
                                                        $event,
                                                        item
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.vertragAutoclose,
                                                    callback: function ($$v) {
                                                      _vm.vertragAutoclose = $$v
                                                    },
                                                    expression:
                                                      "vertragAutoclose",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("v-card-title", [
                                                  _vm._v("Handelszeiten"),
                                                ]),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-radio-group",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.trading
                                                              .tradingtype,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.trading,
                                                              "tradingtype",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "trading.tradingtype",
                                                        },
                                                      },
                                                      [
                                                        _c("v-radio", {
                                                          attrs: {
                                                            label:
                                                              "Standardzeiten verwenden",
                                                            value: "standard",
                                                          },
                                                        }),
                                                        _c("v-radio", {
                                                          attrs: {
                                                            label:
                                                              "wie konfiguriert",
                                                            value:
                                                              "konfiguriert",
                                                          },
                                                        }),
                                                        _vm.trading
                                                          .tradingtype ==
                                                        "konfiguriert"
                                                          ? _c(
                                                              "v-container",
                                                              [
                                                                _c(
                                                                  "v-simple-table",
                                                                  {
                                                                    attrs: {
                                                                      dense: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "tbody",
                                                                      [
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Montag",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .monday
                                                                                            .active,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .trading
                                                                                                .tradingtimes
                                                                                                .monday,
                                                                                              "active",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trading.tradingtimes.monday.active",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Von",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .monday
                                                                                            .from,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Bis",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .monday
                                                                                            .to,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Dienstag",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .tuesday
                                                                                            .active,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .trading
                                                                                                .tradingtimes
                                                                                                .tuesday,
                                                                                              "active",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trading.tradingtimes.tuesday.active",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Von",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .tuesday
                                                                                            .from,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Bis",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .tuesday
                                                                                            .to,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Mittwoch",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .wednesday
                                                                                            .active,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .trading
                                                                                                .tradingtimes
                                                                                                .wednesday,
                                                                                              "active",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trading.tradingtimes.wednesday.active",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Von",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .wednesday
                                                                                            .from,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Bis",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .wednesday
                                                                                            .to,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Donnerstag",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .thursday
                                                                                            .active,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .trading
                                                                                                .tradingtimes
                                                                                                .thursday,
                                                                                              "active",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trading.tradingtimes.thursday.active",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Von",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .thursday
                                                                                            .from,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Bis",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .thursday
                                                                                            .to,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Freitag",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .friday
                                                                                            .active,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .trading
                                                                                                .tradingtimes
                                                                                                .friday,
                                                                                              "active",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trading.tradingtimes.friday.active",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Von",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .friday
                                                                                            .from,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Bis",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .friday
                                                                                            .to,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Samstag",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .saturday
                                                                                            .active,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .trading
                                                                                                .tradingtimes
                                                                                                .saturday,
                                                                                              "active",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trading.tradingtimes.saturday.active",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Von",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .saturday
                                                                                            .from,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Bis",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .saturday
                                                                                            .to,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Sonntag",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .sunday
                                                                                            .active,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .trading
                                                                                                .tradingtimes
                                                                                                .sunday,
                                                                                              "active",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trading.tradingtimes.sunday.active",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Von",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .sunday
                                                                                            .from,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "Bis",
                                                                                        value:
                                                                                          _vm
                                                                                            .trading
                                                                                            .tradingtimes
                                                                                            .sunday
                                                                                            .to,
                                                                                        type: "time",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _vm._v(" Zeitbereiche "),
                                                    _c("v-spacer"),
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label:
                                                          "Neuen Zeitbereich erstellen",
                                                        disabled:
                                                          _vm.$store.state
                                                            .vertraege
                                                            .vertraege[item]
                                                            .konfiguriert,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.createNewZeitbereich(
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.neuerZeitbereichName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.neuerZeitbereichName =
                                                            $$v
                                                        },
                                                        expression:
                                                          "neuerZeitbereichName",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mx-2",
                                                        attrs: {
                                                          fab: "",
                                                          dark: "",
                                                          small: "",
                                                          color:
                                                            "green lighten-2",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.createNewZeitbereich(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-folder-plus"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  { key: _vm.zeitbereichsKey },
                                                  [
                                                    _c(
                                                      "v-expansion-panels",
                                                      _vm._l(
                                                        Object.keys(
                                                          _vm.$store.state
                                                            .vertraege
                                                            .vertraege[item]
                                                            .zeitbereiche
                                                        ),
                                                        function (
                                                          zeitbereich,
                                                          j
                                                        ) {
                                                          return _c(
                                                            "v-expansion-panel",
                                                            { key: j },
                                                            [
                                                              _c(
                                                                "v-expansion-panel-header",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      zeitbereich
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-expansion-panel-content",
                                                                [
                                                                  _c(
                                                                    "v-container",
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "Stopp-Loss",
                                                                                      filled:
                                                                                        "",
                                                                                      type: "number",
                                                                                      step: ".01",
                                                                                      prefix:
                                                                                        "€",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.updateZeitbereich(
                                                                                          $event,
                                                                                          item,
                                                                                          zeitbereich
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .$store
                                                                                          .state
                                                                                          .vertraege
                                                                                          .vertraege[
                                                                                          item
                                                                                        ]
                                                                                          .zeitbereiche[
                                                                                          zeitbereich
                                                                                        ]
                                                                                          .delta,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm
                                                                                              .$store
                                                                                              .state
                                                                                              .vertraege
                                                                                              .vertraege[
                                                                                              item
                                                                                            ]
                                                                                              .zeitbereiche[
                                                                                              zeitbereich
                                                                                            ],
                                                                                            "delta",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "$store.state.vertraege.vertraege[item].zeitbereiche[zeitbereich].delta",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "Limit",
                                                                                      filled:
                                                                                        "",
                                                                                      type: "number",
                                                                                      step: ".01",
                                                                                      prefix:
                                                                                        "€",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.updateZeitbereich(
                                                                                          $event,
                                                                                          item,
                                                                                          zeitbereich
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .$store
                                                                                          .state
                                                                                          .vertraege
                                                                                          .vertraege[
                                                                                          item
                                                                                        ]
                                                                                          .zeitbereiche[
                                                                                          zeitbereich
                                                                                        ]
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm
                                                                                              .$store
                                                                                              .state
                                                                                              .vertraege
                                                                                              .vertraege[
                                                                                              item
                                                                                            ]
                                                                                              .zeitbereiche[
                                                                                              zeitbereich
                                                                                            ],
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "$store.state.vertraege.vertraege[item].zeitbereiche[zeitbereich].value",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            {
                                                                              key: _vm.zeitbereichsTranchenKey,
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-card-text",
                                                                                [
                                                                                  _c(
                                                                                    "v-data-table",
                                                                                    {
                                                                                      staticClass:
                                                                                        "elevation-1",
                                                                                      attrs:
                                                                                        {
                                                                                          headers:
                                                                                            _vm.zeitbereichsHeader,
                                                                                          items:
                                                                                            _vm.zeitbereichTableData(
                                                                                              _vm
                                                                                                .$store
                                                                                                .state
                                                                                                .vertraege
                                                                                                .vertraege[
                                                                                                item
                                                                                              ]
                                                                                                .zeitbereiche[
                                                                                                zeitbereich
                                                                                              ]
                                                                                                .bereiche
                                                                                            ),
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "item.id",
                                                                                              fn: function ({
                                                                                                item,
                                                                                              }) {
                                                                                                return [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        new Date(
                                                                                                          item.id *
                                                                                                            1000
                                                                                                        ).toLocaleString(
                                                                                                          "de-DE"
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                            {
                                                                                              key: "item.actions",
                                                                                              fn: function ({
                                                                                                item,
                                                                                              }) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-2",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          small:
                                                                                                            "",
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.editItem(
                                                                                                              item
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " mdi-pencil "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          small:
                                                                                                            "",
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.deleteItem(
                                                                                                              item,
                                                                                                              zeitbereich
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " mdi-delete "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                            {
                                                                                              key: "top",
                                                                                              fn: function () {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-toolbar",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          flat: "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-toolbar-title",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "Zuordnungen"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-divider",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "mx-4",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              inset:
                                                                                                                "",
                                                                                                              vertical:
                                                                                                                "",
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-spacer"
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-dialog",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              "max-width":
                                                                                                                "500px",
                                                                                                            },
                                                                                                          scopedSlots:
                                                                                                            _vm._u(
                                                                                                              [
                                                                                                                {
                                                                                                                  key: "activator",
                                                                                                                  fn: function ({
                                                                                                                    on,
                                                                                                                    attrs,
                                                                                                                  }) {
                                                                                                                    return [
                                                                                                                      _c(
                                                                                                                        "v-btn",
                                                                                                                        _vm._g(
                                                                                                                          _vm._b(
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "mb-2",
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  color:
                                                                                                                                    "primary",
                                                                                                                                  dark: "",
                                                                                                                                },
                                                                                                                            },
                                                                                                                            "v-btn",
                                                                                                                            attrs,
                                                                                                                            false
                                                                                                                          ),
                                                                                                                          on
                                                                                                                        ),
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            " Neue Zuordnung "
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  },
                                                                                                                },
                                                                                                              ],
                                                                                                              null,
                                                                                                              true
                                                                                                            ),
                                                                                                          model:
                                                                                                            {
                                                                                                              value:
                                                                                                                _vm.zeitbereichsDialog,
                                                                                                              callback:
                                                                                                                function (
                                                                                                                  $$v
                                                                                                                ) {
                                                                                                                  _vm.zeitbereichsDialog =
                                                                                                                    $$v
                                                                                                                },
                                                                                                              expression:
                                                                                                                "zeitbereichsDialog",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-card",
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-card-title",
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "span",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "text-h5",
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          _vm.formTitle
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "v-card-text",
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-container",
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-row",
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "v-col",
                                                                                                                            {
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  cols: "12",
                                                                                                                                },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-text-field",
                                                                                                                                {
                                                                                                                                  attrs:
                                                                                                                                    {
                                                                                                                                      label:
                                                                                                                                        "Beginn",
                                                                                                                                      type: "datetime-local",
                                                                                                                                    },
                                                                                                                                  model:
                                                                                                                                    {
                                                                                                                                      value:
                                                                                                                                        _vm
                                                                                                                                          .editedItem
                                                                                                                                          .id,
                                                                                                                                      callback:
                                                                                                                                        function (
                                                                                                                                          $$v
                                                                                                                                        ) {
                                                                                                                                          _vm.$set(
                                                                                                                                            _vm.editedItem,
                                                                                                                                            "id",
                                                                                                                                            $$v
                                                                                                                                          )
                                                                                                                                        },
                                                                                                                                      expression:
                                                                                                                                        "editedItem.id",
                                                                                                                                    },
                                                                                                                                }
                                                                                                                              ),
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          ),
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      ),
                                                                                                                      _c(
                                                                                                                        "v-row",
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "v-col",
                                                                                                                            {
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  cols: "12",
                                                                                                                                },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-select",
                                                                                                                                {
                                                                                                                                  attrs:
                                                                                                                                    {
                                                                                                                                      items:
                                                                                                                                        _vm.getTranchenList(
                                                                                                                                          item,
                                                                                                                                          zeitbereich
                                                                                                                                        ),
                                                                                                                                      chips:
                                                                                                                                        "",
                                                                                                                                      label:
                                                                                                                                        "zugeordnete Tranchen ",
                                                                                                                                      multiple:
                                                                                                                                        "",
                                                                                                                                    },
                                                                                                                                  model:
                                                                                                                                    {
                                                                                                                                      value:
                                                                                                                                        _vm
                                                                                                                                          .editedItem
                                                                                                                                          .namen,
                                                                                                                                      callback:
                                                                                                                                        function (
                                                                                                                                          $$v
                                                                                                                                        ) {
                                                                                                                                          _vm.$set(
                                                                                                                                            _vm.editedItem,
                                                                                                                                            "namen",
                                                                                                                                            $$v
                                                                                                                                          )
                                                                                                                                        },
                                                                                                                                      expression:
                                                                                                                                        "editedItem.namen",
                                                                                                                                    },
                                                                                                                                }
                                                                                                                              ),
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          ),
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      ),
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  ),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "v-card-actions",
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-spacer"
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          color:
                                                                                                                            "blue darken-1",
                                                                                                                          disabled:
                                                                                                                            _vm
                                                                                                                              .$store
                                                                                                                              .state
                                                                                                                              .vertraege
                                                                                                                              .vertraege[
                                                                                                                              item
                                                                                                                            ]
                                                                                                                              .konfiguriert,
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          _vm.close,
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " Cancel "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          color:
                                                                                                                            "blue darken-1",
                                                                                                                          disabled:
                                                                                                                            _vm
                                                                                                                              .$store
                                                                                                                              .state
                                                                                                                              .vertraege
                                                                                                                              .vertraege[
                                                                                                                              item
                                                                                                                            ]
                                                                                                                              .konfiguriert,
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            return _vm.save(
                                                                                                                              item,
                                                                                                                              zeitbereich
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " Save "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-dialog",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              "max-width":
                                                                                                                "500px",
                                                                                                            },
                                                                                                          model:
                                                                                                            {
                                                                                                              value:
                                                                                                                _vm.zeitbereichsDialogDelete,
                                                                                                              callback:
                                                                                                                function (
                                                                                                                  $$v
                                                                                                                ) {
                                                                                                                  _vm.zeitbereichsDialogDelete =
                                                                                                                    $$v
                                                                                                                },
                                                                                                              expression:
                                                                                                                "zeitbereichsDialogDelete",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-card",
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-card-title",
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "text-h5",
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "Sind Sie sicher, dass Sie diese Zuordnung löschen wollen?"
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "v-card-actions",
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-spacer"
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          color:
                                                                                                                            "blue darken-1",
                                                                                                                          disabled:
                                                                                                                            _vm
                                                                                                                              .$store
                                                                                                                              .state
                                                                                                                              .vertraege
                                                                                                                              .vertraege[
                                                                                                                              item
                                                                                                                            ]
                                                                                                                              .konfiguriert,
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          _vm.closeDelete,
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "Cancel"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          color:
                                                                                                                            "blue darken-1",
                                                                                                                          disabled:
                                                                                                                            _vm
                                                                                                                              .$store
                                                                                                                              .state
                                                                                                                              .vertraege
                                                                                                                              .vertraege[
                                                                                                                              item
                                                                                                                            ]
                                                                                                                              .konfiguriert,
                                                                                                                          text: "",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          _vm.deleteItemConfirm,
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "OK"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "v-spacer"
                                                                                                                  ),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                              proxy: true,
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("v-card-title", [
                                                  _vm._v("Tranchen"),
                                                ]),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("v-simple-table", {
                                                      key: _vm.tranchenKey,
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function () {
                                                              return [
                                                                _c("thead", [
                                                                  _c("tr", [
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Tranche "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Status "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Datum "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c(
                                                                  "tbody",
                                                                  _vm._l(
                                                                    _vm.sortedTranchen(
                                                                      item
                                                                    ),
                                                                    function (
                                                                      value
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key: value.sort,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "Tranche " +
                                                                                  _vm._s(
                                                                                    value.sort
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  value.status
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          value.timestamp
                                                                            ? _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      new Date(
                                                                                        value
                                                                                          .timestamp
                                                                                          .seconds *
                                                                                          1000
                                                                                      ).toLocaleString(
                                                                                        "de-DE"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                key: _vm.vertragsnamenKey,
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("v-card-title", [
                                                  _vm._v("Vertragsnummern"),
                                                ]),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c("v-simple-table", {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function () {
                                                              return [
                                                                _c("thead", [
                                                                  _c("tr", [
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " # "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Vertrag "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Vertragsnehmer "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _c(
                                                                  "tbody",
                                                                  _vm._l(
                                                                    _vm.$store
                                                                      .state
                                                                      .vertraege
                                                                      .vertragsnummern[
                                                                      item
                                                                    ],
                                                                    function (
                                                                      vname
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key: vname.name,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$store.state.vertraege.vertragsnummern[
                                                                                    item
                                                                                  ].indexOf(
                                                                                    vname
                                                                                  ) +
                                                                                    1
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  vname
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .$store
                                                                                    .state
                                                                                    .vertraege
                                                                                    .vertragsnehmer[
                                                                                    vname
                                                                                  ]
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }